<div class="h-full bg-background-app_bar text-foreground-app_bar overflow-auto">
  <div class="flex flex-col gap-3">
    <div class="mx-2 flex justify-between pt-3 align-middle text-xl">
      <div (click)="onCloseClicked()" class="my-auto cursor-pointer">
        <i class="fa-solid fa-arrow-right-from-line"></i>
      </div>
      <span class="text-2xl">Benachrichtigungen</span>
    </div>
    <div class="h-full">
      <div class="pr-1 text-right">
        <button
          *ngIf="notifications$ | async as notifications"
          type="button"
          (click)="markAllAsRead(notifications)"
        >
          Alle als gelesen markieren
        </button>
      </div>
      <div
        class="mx-2 my-2 flex h-fit w-[300px] items-center justify-between gap-2 rounded border border-l-8 border-r-8 border-solid bg-background-background p-2"
        [ngStyle]="{ 'border-color': data.colorCode ?? 'transparent' }"
        *ngFor="let data of notifications$ | async"
        (click)="notificationDialog.visible = true; selectedNotification = data"
      >
        <div class="flex w-full cursor-pointer flex-col gap-1">
          <span class="text-xl font-semibold text-foreground-text">
            {{ data.title }}
          </span>
          <span class="text-wrap text-sm text-foreground-text">
            {{ data.message }}
          </span>
          <button
            ejs-button
            type="button"
            title="Als gelesen markieren"
            class="e-outline ml-auto flex w-fit items-center gap-1 rounded border-none p-1"
            (click)="onReadClick(data); $event.stopPropagation()"
            *ngIf="data.isReadable"
          >
            <i class="fa-solid fa-envelope-circle-check gradient-icon"></i>
            <span class="text-[10px]">Als gelesen markieren</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ejs-dialog
  #notificationDialog
  [showCloseIcon]="true"
  header="Benachrichtigung"
  width="600"
  [visible]="false"
  isModal="true"
  (overlayClick)="notificationDialog.hide()"
>
  <ng-template #content>
    <div *ngIf="selectedNotification">
      <div class="text-lg">{{ selectedNotification.title }}</div>
      <div>{{ selectedNotification.message }}</div>
      <button
        *ngIf="selectedNotification.isReadable"
        ejs-button
        type="button"
        class="icon-button float-right mt-3"
        title="Als gelesen markieren"
        (click)="
          onReadClick(selectedNotification); notificationDialog.visible = false
        "
      >
        <div>
          <i class="fa-solid fa-envelope-circle-check gradient-icon"></i>
          <span>Als gelesen markieren</span>
        </div>
      </button>
    </div>
  </ng-template>
</ejs-dialog>
