import { LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { SidebarModule } from '@syncfusion/ej2-angular-navigations';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { NgxAuthenticationModule, getAuthToken } from 'ngx-authentication';
import {
  ErrorBarComponent,
  LoadingScreenComponent,
  NgxCommonSolutionModule,
  ProgressBarComponent,
  SideMenuComponent,
  TopMenuComponent,
} from 'ngx-common-solution';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataPrivacyFormComponent } from './data-privacy-form/data-privacy-form.component';
import { HomeComponent } from './home/home.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { menuItems } from './menu-items';
import { MyCommonModule } from './my-common/my-common.module';
import { NotificationBoardComponent } from './notification-board/notification-board.component';
import { SharedModule } from './shared/shared.module';
import { SitePermissionAlertComponent } from './site-permission-alert/site-permission-alert.component';
import {
  TranslocoHttpLoader,
  TranslocoRootModule,
} from './transloco-root.module';
registerLocaleData(localeDe);
// declare var require: any;
// loadCldr(
//   require('cldr-data/main/de/numbers.json'),
//   require('cldr-data/supplemental/numberingSystems.json'),
//   require('cldr-data/main/de/ca-gregorian.json'),
//   require('cldr-data/main/de/timeZoneNames.json'),
//   require('cldr-data/supplemental/weekdata.json'),
// );

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DataPrivacyFormComponent,
    SitePermissionAlertComponent,
    ImpressumComponent,
    NotificationBoardComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslocoRootModule,
    MyCommonModule,
    SharedModule,
    ToastModule,
    ListViewModule,
    SidebarModule,
    TopMenuComponent,
    LoadingScreenComponent,
    ProgressBarComponent,
    SideMenuComponent,
    ErrorBarComponent,
    NgxCommonSolutionModule.forRoot({
      iconPath: 'assets/icons/',
      menuItems: menuItems,
      apiBaseUrl: environment.authBaseUrl,
      getToken: getAuthToken,
      Permissions: EnumPermissions,
    }),
    MatIconModule,
    NgxAuthenticationModule.forRoot({
      allowedDomains: environment.allowedDomains,
      apiBaseUrl: environment.authBaseUrl,
      appTitle: 'Tracking',
      secondaryAppTitle: 'Tracking Remastered 2023',
      logo: 'location_GPS.svg',
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    TranslocoHttpLoader,
    // { provide: HTTP_INTERCEPTORS, useValue: authInterceptor, multi: true },
  ],
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconResolver((name, namespace) => {
      return namespace === ''
        ? {
            url: sanitizer.bypassSecurityTrustResourceUrl(
              `/assets/icons/${name}.svg`,
            ),
            options: { viewBox: '0 0 48 48' },
          }
        : null;
    });
  }
}
