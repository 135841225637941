import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
// import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
// registerLicense('ORg4AjUWIQA/Gnt2UVhhQlVCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5SdEdjXH5cc3NdQWVY');

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
