import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {
  PermissionAlert,
  SitePermissionAlertService,
  UserPermissionsStoreService,
} from 'ngx-common-solution';
import { Observable, Subject, combineLatest, takeUntil, tap } from 'rxjs';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-site-permission-alert',
  templateUrl: './site-permission-alert.component.html',
  styleUrls: ['./site-permission-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SitePermissionAlertComponent implements OnDestroy {
  private onDestroy$ = new Subject();
  EP = EnumPermissions;
  alert$: Observable<PermissionAlert | undefined>;
  constructor(
    private sitePermissionAlertService: SitePermissionAlertService,
    private route: ActivatedRoute,
    private router: Router,
    permissionStore: UserPermissionsStoreService,
  ) {
    combineLatest([this.route.queryParams, permissionStore.userPermissions$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([params, permissions]) => {
        const redirectURL = params['redirectURL'];
        if (redirectURL) {
          this.router.navigateByUrl(redirectURL);
        } else {
          this.router.navigate(['']);
        }
        if (permissions.length > 0) {
          const redirectURL = params['redirectURL'];
          if (redirectURL) {
            this.router.navigateByUrl(redirectURL);
          }
        }
      });
    this.alert$ = sitePermissionAlertService.alert$;
  }
  ngOnDestroy(): void {
    this.sitePermissionAlertService.sendAlert(undefined);
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
