import { Component } from '@angular/core';

@Component({
  selector: 'app-data-privacy-form',
  templateUrl: './data-privacy-form.component.html',
  styleUrls: ['./data-privacy-form.component.scss']
})
export class DataPrivacyFormComponent {

}
