import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
  provideRouter,
  withComponentInputBinding,
} from '@angular/router';
import { HomeComponent } from './home/home.component';
import { canActivate } from 'ngx-authentication';
import { SitePermissionAlertComponent } from './site-permission-alert/site-permission-alert.component';
import { DataPrivacyFormComponent } from './data-privacy-form/data-privacy-form.component';

import { ImpressumComponent } from './impressum/impressum.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [canActivate],
  },
  {
    path: 'location',
    loadChildren: () =>
      import('./location/location.module').then((m) => m.LocationModule),
    canActivate: [canActivate],
    title: 'Orte',
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    canActivate: [canActivate],
  },
  {
    path: 'role',
    loadChildren: () => import('./role/role.module').then((m) => m.RoleModule),
    canActivate: [canActivate],
  },
  {
    path: 'address',
    loadChildren: () =>
      import('./address/address.module').then((m) => m.AddressModule),
    canActivate: [canActivate],
  },
  {
    path: 'team',
    loadChildren: () => import('./team/team.module').then((m) => m.TeamModule),
    canActivate: [canActivate],
  },
  {
    path: 'objecttype',
    loadChildren: () =>
      import('./object-type/object-type.module').then(
        (m) => m.ObjectTypeModule,
      ),
    canActivate: [canActivate],
  },
  {
    path: 'notification',
    loadChildren: () =>
      import('./notification/notification.module').then(
        (m) => m.NotificationModule,
      ),
    canActivate: [canActivate],
  },
  {
    path: 'object',
    loadChildren: () =>
      import('./object/object.module').then((m) => m.ObjectModule),
    canActivate: [canActivate],
  },
  {
    path: 'transport',
    loadChildren: () =>
      import('./transport/transport.module').then((m) => m.TransportModule),
    canActivate: [canActivate],
  },
  {
    path: 'transfer',
    loadChildren: () =>
      import('./transfer/transfer.module').then((m) => m.TransferModule),
    canActivate: [canActivate],
  },
  {
    path: 'automation',
    loadChildren: () =>
      import('./transfer-automation/transfer-automation.module').then(
        (m) => m.TransferAutomationModule,
      ),
    canActivate: [canActivate],
  },
  {
    path: 'error',
    component: SitePermissionAlertComponent,
  },
  {
    path: 'dataprivacy',
    component: DataPrivacyFormComponent,
  },
  {
    path: 'impressum',
    component: ImpressumComponent,
  },
  {
    path: '',
    loadChildren: () =>
      import('ngx-authentication').then((m) => m.NgxAuthenticationModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [provideRouter(routes, withComponentInputBinding())],
})
export class AppRoutingModule {}
