<div class="mx-2 mt-5 flex max-w-xl flex-col gap-10 sm:mx-auto sm:w-full">
  <div class="w-full text-center">
    <div class="m-auto w-auto">
      <!--       <sol-image
        icon="location_GPS.svg"
        class="svg-icon-gradient m-auto block h-16 w-16"
      ></sol-image> -->
      <i class="fa-kit fa-tracking-logo gradient-icon mb-2 text-7xl"></i>
    </div>
    <span class="text-2xl font-semibold">Tracking</span>
  </div>
  <div>
    <ng-container *ngIf="userName$ | async"
      >Hallo
      <span class="font-semibold">{{ userName$ | async }}</span>
      <div *ngIf="currentUserHasNoPermissions$ | async">
        Sie besitzen aktuell keine Rechte. Bitte kontaktieren Sie den
        Administrator der Benutzerrechte in Ihrer Organisation.
      </div>
      <div *ngIf="!(currentUserHasNoPermissions$ | async)">
        Ihr aktuelles Team ist:
        <span class="font-semibold">{{
          (currentTeamName$ | async) ?? "Nicht ausgewählt"
        }}</span>
      </div>
    </ng-container>
  </div>
  <ng-container *checkPermissions="let c">
    <div class="button-grid m-auto">
      <button
        type="button"
        class="big-icon-button"
        routerLink="object/transfer/delivery"
        [ifButtonPermission]="EP.TransferWrite"
      >
        <div class="flex flex-col">
          <span>Anlieferung</span>
          <i class="fa-kit fa-regular-cubes-circle-plus"></i>
        </div>
      </button>
      <button
        type="button"
        class="big-icon-button"
        routerLink="object/identify"
        [ifButtonPermission]="EP.TransferWrite"
      >
        <div class="flex flex-col">
          <span>Identifizieren</span>
          <i class="fa-kit fa-regular-box-open-circle-question"></i>
        </div>
      </button>
      <button
        type="button"
        class="big-icon-button"
        routerLink="object/transfer"
        [ifButtonPermission]="EP.TransferWrite"
      >
        <div class="flex flex-col">
          <span>Umlagerung</span>
          <i class="fa-kit fa-regular-cube-circle-arrow-right"></i>
        </div>
      </button>
      <button
        type="button"
        class="big-icon-button"
        routerLink="object"
        [ifButtonPermission]="EP.TrackingobjectWrite"
      >
        <div class="flex flex-col">
          <span>Objekt erstellen</span>
          <i class="fa-kit fa-regular-cube-circle-plus"></i>
        </div>
      </button>
      <button
        type="button"
        class="big-icon-button"
        routerLink="object/list"
        [ifButtonPermission]="EP.TrackingobjectRead"
      >
        <div class="flex flex-col">
          <span>Objekte</span>
          <i class="fa-kit fa-regular-cube-pen"></i>
        </div>
      </button>
    </div>
  </ng-container>
</div>
