<div class="component-wrapper mx-2">
  <div class="m-auto max-w-7xl">
    <h1 class="text-2xl">Angaben gemäß § 5 TMG</h1>
    <div class="mt-5">
      A&T Solution GmbH<br />
      Flagbalger Straße 5<br />
      26954 Nordenham
    </div>
    <br />
    Handelsregister: 209797<br />
    Registergericht: Amtsgericht Oldenburg<br />
    <br />
    <b>Vertreten durch den Geschäftsführer:</b><br />
    Oktay Erden<br />
    <br />
    <b>Kontakt</b><br />
    Telefon: 04731-3909490<br />
    E-Mail: connect&#64;a-t-solution.de<br />
    <br />
    <b> Umsatzsteuer-ID</b><br />
    Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br />
    DE815556536<br />
    <br />
    Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
    einer Verbraucherschlichtungsstelle teilzunehmen.<br />
    <br />
    <b>Haftung für Inhalte</b><br />
    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
    diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10
    TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte
    oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
    forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
    <br />
    <br />
    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
    nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche
    Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
    Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
    Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
    <br />
    <br />
    <b>Haftung für Links</b><br />
    Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte
    wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch
    keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
    jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
    Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
    überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
    erkennbar.
    <br />
    <br />
    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
    konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
    Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
    entfernen.
    <br />
    <br />
    <b>Urheberrecht</b><br />
    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
    unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
    Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
    Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors
    bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
    privaten, nicht kommerziellen Gebrauch gestattet.
    <br />
    <br />
    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
    werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
    Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
    entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir
    derartige Inhalte umgehend entfernen.
    <br />
    <br />
    Quelle:
    <a routerLink="https://www.e-recht24.de">https://www.e-recht24.de</a>
  </div>
</div>
