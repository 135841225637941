<ng-container *ngIf="alert$ | async as alert">
  <ng-container *transloco="let t">
    <div class="m-2 h-full w-full rounded border border-gray-300">
      <div class="m-auto w-fit">
        <div class="mx-2 my-4 italic">
          Sie können die Seite
          <a
            style="color: revert; text-decoration: revert"
            [routerLink]="alert.url"
            >{{ alert.url }}</a
          >
          nur mit folgenden Rechten aufrufen:<br />
          <div *ngFor="let permission of alert.permissions">
            {{ t("Permission." + EP[permission]) }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
