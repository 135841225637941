import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import {
  ChangeEventArgs,
  SelectEventArgs,
} from '@syncfusion/ej2-angular-dropdowns';
import {
  AUTHENTICATION_DEFAULT_OPTIONS,
  AuthenticationConfig,
  AuthenticationStoreService,
  AuthUserGetDto,
} from 'ngx-authentication';
import {
  ComponentStateService,
  GlobalErrorService,
  GlobalLoadingBarService,
  MenuStoreService,
} from 'ngx-common-solution';
import { Observable, map } from 'rxjs';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { UserTeamGetDto } from 'src/dto/GetDtos/user-team-get-dto';
import { SelectedObjectService } from 'src/services/util/selected-object.service';
import { CurrentUserStoreService } from 'src/stores/current-user-store.service';
import { Location } from '@angular/common';
import { SearchModel } from 'src/services/util/search-model';
import { SelectedLocationService } from 'src/services/util/selected-location.service';
import { menuItems } from './menu-items';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStateService],
})
export class AppComponent implements OnInit, AfterViewInit {
  EP = EnumPermissions;
  @ViewChild('rightSidebar') rightSidebar?: SidebarComponent;
  globalLoading$: Observable<boolean>;
  loading$: Observable<boolean>;
  errorsExist$: Observable<boolean>;
  isAuthenticated$: Observable<boolean>;
  appTitle = this.authConfig?.appTitle;
  currentUser$: Observable<AuthUserGetDto | null>;
  userTeams$: Observable<UserTeamGetDto[]>;
  currentTeam$: Observable<UserTeamGetDto | undefined>;
  notificationsExist$: Observable<boolean>;
  notificationsShownAfterLogin = false;
  constructor(
    @Inject(AUTHENTICATION_DEFAULT_OPTIONS)
    private authConfig: AuthenticationConfig,
    private swUpdate: SwUpdate,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private loadingBarService: GlobalLoadingBarService,
    private globalErrorService: GlobalErrorService,
    private authStore: AuthenticationStoreService,
    private currentUserStore: CurrentUserStoreService,
    private componentState: ComponentStateService,
    private selectedObjectService: SelectedObjectService,
    private selectedLocationService: SelectedLocationService,
    private menuStore: MenuStoreService,
    cdr: ChangeDetectorRef,
  ) {
    this.isAuthenticated$ = this.authStore.isAuthenticated$;
    this.loading$ = componentState.loading$;
    this.globalLoading$ = this.loadingBarService.loading$;
    this.errorsExist$ = this.globalErrorService.errors$.pipe(
      map((errors) => errors.length > 0),
    );
    this.notificationsExist$ = this.currentUserStore.notifications$.pipe(
      map((notifications) => notifications.length > 0),
    );
    this.isAuthenticated$.subscribe((r) => {
      if (!r) {
        this.notificationsShownAfterLogin = false;
        cdr.detectChanges();
      }
    });
    this.currentUser$ = this.authStore.authUser$;
    this.userTeams$ = this.currentUserStore.userTeams$;
    this.currentTeam$ = this.currentUserStore.currentTeam$;

    this.authStore.authUser$.subscribe((authUser) => {
      if (authUser?.isSystemUser) {
        const newMenuItems = menuItems.map((item) => {
          if (item.text == 'Funktionen') {
            return {
              ...item,
              children: [
                ...(item.children ?? []),
                {
                  permissions: [EnumPermissions.TransferWrite],
                  iconText: 'fa-regular fa-arrow-right-from-bracket',
                  link: '../object/bookout',
                  text: 'Objekte ausbuchen',
                  primary: true,
                },
              ],
            };
          } else return item;
        });
        this.menuStore.setMenuItems(newMenuItems);
      } else {
        this.menuStore.setMenuItems(menuItems);
      }
    });
  }
  ngOnInit(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event: VersionEvent) => {
        if (event.type == 'VERSION_READY') {
          if (confirm('Neue Version vorhanden. Jetzt laden?')) {
            window.location.reload();
          }
        }
      });
    }
  }
  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loadingBarService.setLoading(true);
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.loadingBarService.setLoading(false);
      }
    });
  }
  onCurrentTeamSelect(e: SelectEventArgs) {
    this.currentUserStore.setTeam(e.itemData as UserTeamGetDto);
  }
  onCurrentTeamSelectFromListView(e: any) {
    this.currentUserStore.setTeam(e.data as UserTeamGetDto);
  }
  onTeamDefaultSet(userTeam: UserTeamGetDto) {
    this.currentUserStore.setDefaultTeam(userTeam).subscribe();
  }
  onChange(e: ChangeEventArgs) {
    if (!e.itemData) {
      this.currentUserStore.setTeam(undefined);
    }
  }
  onSearchCompleted(newItem: SearchModel | undefined) {
    if (newItem && !newItem.location) {
      if (
        this.selectedObjectService.selectedObject?.id !=
        newItem.trackingobject?.id
      ) {
        this.selectedObjectService.setSelectedObject(newItem.trackingobject);
        if (newItem.trackingobject) {
          // const url = this.router
          //   .createUrlTree([], {
          //     relativeTo: this.route,
          //     queryParams: { objectId: newItem.id },
          //   })
          //   .toString();
          this.router.navigate(['/object/search/'], {
            relativeTo: this.route,
            queryParams: { objectId: newItem.trackingobject.id },
          });
          // this.location.go(url);
        } else {
          const url = this.router
            .createUrlTree([], {
              relativeTo: this.route,
              queryParams: { objectId: undefined },
            })
            .toString();
          this.location.go(url);
        }
      } else {
        this.selectedObjectService.reload();
      }
    } else if (newItem) {
      if (
        this.selectedLocationService.selectedLocation?.id !=
        newItem.location?.id
      ) {
        this.selectedLocationService.setSelectedLocation(newItem.location);
        if (newItem.location) {
          // const url = this.router
          //   .createUrlTree([], {
          //     relativeTo: this.route,
          //     queryParams: { objectId: newItem.id },
          //   })
          //   .toString();
          this.router.navigate(['/location/dashboard/'], {
            relativeTo: this.route,
            queryParams: { locationId: newItem.location.id },
          });
          // this.location.go(url);
        } else {
          const url = this.router
            .createUrlTree([], {
              relativeTo: this.route,
              queryParams: { locationId: undefined },
            })
            .toString();
          this.location.go(url);
        }
      } else {
        this.selectedLocationService.reload();
      }
    }
  }
}
