import { MenuLink } from 'ngx-common-solution';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';

export const menuItems: MenuLink[] = [
  {
    text: 'Startseite',
    iconText: 'fa-regular fa-grid gradient-icon mr-2 w-[25px]',
    link: '/',
  },
  {
    text: 'Funktionen',
    expanded: true,
    selectable: false,
    children: [
      {
        permissions: [EnumPermissions.TransferWrite],
        iconText: 'fa-kit fa-regular-cubes-circle-plus ',
        link: 'object/transfer/delivery',
        text: 'Objekte anliefern',
        primary: true,
      },
      {
        permissions: [EnumPermissions.TransferWrite],
        iconText: 'fa-kit fa-regular-box-open-circle-question ',
        link: 'object/identify',
        text: 'Objekte identifizieren',
        primary: true,
      },
      {
        permissions: [EnumPermissions.TransferWrite],
        iconText: 'fa-regular fa-circle-arrow-right',
        link: '../object/transfer',
        text: 'Objekt umlagern',
        primary: true,
      },
      {
        permissions: [EnumPermissions.TrackingobjectWrite],
        iconText: 'fa-regular fa-circle-plus',
        link: '../object',
        text: 'Objekt erstellen',
        primary: true,
      },
      {
        permissions: [EnumPermissions.TrackingobjectWrite],
        iconText: 'fa-regular fa-circle-exclamation',
        link: '../object/prioritize',
        text: 'Objekte priorisieren',
        primary: true,
      },
    ],
  },
  {
    text: 'Infos',
    expanded: true,
    selectable: false,
    children: [
      {
        permissions: [EnumPermissions.TrackingobjectRead],
        iconText: 'fa-regular fa-cube ',
        link: '../object/list',
        text: 'Objektliste',
        primary: true,
      },
      {
        permissions: [EnumPermissions.TransferRead],
        iconText: 'fa-regular fa-right-left',
        link: 'transfer/list',
        text: 'Transferliste',
      },
      {
        permissions: [EnumPermissions.TransportRead],
        iconText: 'fa-regular fa-truck-arrow-right',
        link: 'transport/list',
        text: 'Transportliste',
      },
    ],
  },
  {
    permissions: [EnumPermissions.CoreDataRead],
    text: 'Stammdaten',
    selectable: false,
    children: [
      {
        permissions: [EnumPermissions.CoreDataRead],
        iconText: 'fa-regular fa-location-dot',
        link: 'location/list',
        text: 'Orte',
      },
      {
        permissions: [EnumPermissions.CoreDataRead],
        iconText: 'fa-regular fa-address-book',
        link: 'address/list',
        text: 'Adressen',
      },
      {
        permissions: [EnumPermissions.CoreDataRead],
        iconText: 'fa-regular fa-cube',
        link: 'objecttype/list',
        text: 'Objekt-Typen',
      },
    ],
  },
  {
    text: 'Konfiguration',
    expanded: true,
    selectable: false,
    children: [
      {
        permissions: [EnumPermissions.UserWrite],
        iconText: 'fa-regular fa-user',
        link: 'account/list',
        text: 'Benutzerverwaltung',
      },
      {
        permissions: [EnumPermissions.TeamRead],
        iconText: 'fa-regular fa-users',
        link: 'team/list',
        text: 'Teams',
      },
      {
        permissions: [EnumPermissions.RoleRead],
        iconText: 'fa-regular fa-users-rectangle',
        link: 'role/list',
        text: 'Rollen',
      },
      {
        permissions: [EnumPermissions.TransferAutomationRead],
        iconText: 'fa-regular fa-bolt-auto gradient-icon mr-2 w-[25px]',
        link: 'automation/list',
        text: 'Transfer-Automation',
      },
      {
        permissions: [EnumPermissions.NotificationRead],
        iconText: 'fa-regular fa-bells',
        link: 'notification/list',
        text: 'Benachrichtigungen',
      },
    ],
  },
  {
    iconText: 'fa-regular fa-right-from-bracket',
    link: 'logout',
    text: 'Abmelden',
    primary: false,
  },
  {
    iconText: '',
    link: 'impressum',
    text: 'Impressum',
    primary: false,
    anonymous: true,
  },
  {
    iconText: '',
    link: 'https://www.a-t-solution.de/datenschutzerklaerung/',
    text: 'Datenschutzerklärung',
    primary: false,
    anonymous: true,
    external: true,
  },
];
