<div *ngIf="globalLoading$ | async" class="absolute z-40 w-full opacity-90">
  <sol-progress-bar></sol-progress-bar>
</div>
<sol-loading-screen>
  <div class="h-full w-full" data-testid="router-content">
    <sol-top-menu
      class="w-full"
      (menuClick)="sideMenu.toggle()"
      [showLogout]="(isAuthenticated$ | async) ?? false"
      [barTitle]="appTitle"
    >
      <ng-container *checkPermissions="let c">
        <ng-container *ngIf="isAuthenticated$ | async">
          <div class="">
            <div
              class="pointer-events-none absolute -top-[15px] left-0 right-0 flex w-full justify-center"
            >
              <app-search-input
                class="top-search-bar pointer-events-auto"
                [header]="'Suchen'"
                (searchCompleted)="onSearchCompleted($event)"
                [showResult]="false"
              ></app-search-input>
            </div>
            <div class="mx-2 flex justify-end gap-2">
              <ejs-dropdownlist
                [showClearButton]="true"
                [dataSource]="userTeams$ | async"
                [fields]="{ text: 'teamName', value: 'teamId' }"
                placeholder="Aktuelles Team"
                (select)="onCurrentTeamSelect($event)"
                (change)="onChange($event)"
                [value]="(currentTeam$ | async)?.teamId"
                class="hidden sm:block"
              >
                <ng-template #itemTemplate="" let-data="">
                  <div class="flex">
                    <div class="w-full">{{ data.teamName }}</div>
                    <div class="mt-auto">
                      <button
                        class="e-btn relative -top-1 ml-auto block px-[7px] py-0 hover:bg-primary-400"
                        [ngClass]="{ 'bg-primary-400': data.isDefault }"
                        (click)="onTeamDefaultSet(data)"
                        title="Standard Team"
                      >
                        Standard
                      </button>
                    </div>
                  </div>
                </ng-template>
              </ejs-dropdownlist>
              <button
                class="block sm:hidden"
                (click)="currentTeamDialog.show()"
              >
                <i class="fa-regular fa-users gradient-icon"></i>
              </button>
              <div class="relative mx-1 inline-block">
                <button
                  #notificationButton
                  class="rounded-full px-[6px] text-lg hover:bg-gray-500"
                  (click)="rightSidebar?.toggle()"
                >
                  <i class="fa-solid fa-bell gradient-icon"></i>
                </button>
                <span
                  *ngIf="notificationsExist$ | async"
                  style="background-color: #dc2626"
                  class="e-badge e-badge-danger e-badge-overlap e-badge-dot -top-[1px] left-[85%]"
                ></span>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </sol-top-menu>
    <sol-side-menu
      #sideMenu
      [isAuthenticated]="(isAuthenticated$ | async) ?? false"
      class=""
      customClass="h-full-without-header w-[300px]"
    >
      <div class="h-full">
        <div class="h-full-without-header flex flex-col overflow-auto">
          <router-outlet></router-outlet>
        </div>
      </div>
      <ejs-sidebar
        *ngIf="isAuthenticated$ | async"
        #rightSidebar
        class="h-full-without-header top-12 w-fit"
        [type]="'Over'"
        [enableGestures]="true"
        [position]="'Right'"
        [isOpen]="
          !notificationsShownAfterLogin && (notificationsExist$ | async)
        "
        [closeOnDocumentClick]="false"
        (close)="notificationsShownAfterLogin = true"
      >
        <app-notification-board
          (closeClicked)="rightSidebar.hide()"
        ></app-notification-board>
      </ejs-sidebar>
    </sol-side-menu>
  </div>
</sol-loading-screen>
<ejs-toast
  #globalErrorToast
  id="globalErrorToast"
  class="e-toast-container e-toast-top-right absolute z-50 flex w-80"
  [position]="'{ X: \'Right\', Y: \'Top\' }'"
  *ngIf="errorsExist$ | async"
>
  <sol-error-bar class="e-toast e-toast-message"></sol-error-bar>
</ejs-toast>

<ejs-dialog
  #currentTeamDialog
  [showCloseIcon]="true"
  width="600"
  [visible]="false"
  [closeOnEscape]="true"
  isModal="true"
  [header]="'Team auswählen'"
>
  <ng-template #content>
    <ejs-listview
      [dataSource]="userTeams$ | async"
      [fields]="{ text: 'teamName', id: 'teamId' }"
      (select)="
        onCurrentTeamSelectFromListView($event); currentTeamDialog.hide()
      "
    >
      <ng-template #template="" let-data="">
        <div class="flex items-center justify-between">
          <div>{{ data.teamName }}</div>
          <div>
            <button
              class="e-btn relative ml-auto block px-[7px] py-0 hover:bg-primary-400"
              [ngClass]="{ 'bg-primary-400': data.isDefault }"
              (click)="onTeamDefaultSet(data)"
              title="Standard Team"
            >
              Standard
            </button>
          </div>
        </div>
      </ng-template>
    </ejs-listview>
  </ng-template>
</ejs-dialog>
