import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { filter, from, mergeMap, switchMap, tap } from 'rxjs';
import { NotificationGetDto } from 'src/dto/GetDtos/notification-get-dto';
import { NotificationService } from 'src/services/http/notification.service';
import { CurrentUserStoreService } from 'src/stores/current-user-store.service';

@Component({
  selector: 'app-notification-board',
  templateUrl: './notification-board.component.html',
  styleUrl: './notification-board.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationBoardComponent {
  @Output() closeClicked = new EventEmitter();
  notifications$;
  selectedNotification?: NotificationGetDto;
  constructor(
    private currentUserStore: CurrentUserStoreService,
    private notificationService: NotificationService,
  ) {
    this.notifications$ = currentUserStore.notifications$;
  }
  onReadClick(notification: NotificationGetDto) {
    this.notificationService
      .markNotificationAsRead(notification.id)
      .subscribe((r) =>
        this.currentUserStore.updateNotificationAsRead(notification.id),
      );
  }
  markAllAsRead(notifications: NotificationGetDto[]) {
    from(notifications)
      .pipe(
        filter((notification) => notification.isReadable),
        mergeMap((notification) =>
          this.notificationService
            .markNotificationAsRead(notification.id)
            .pipe(
              tap((r) =>
                this.currentUserStore.updateNotificationAsRead(notification.id),
              ),
            ),
        ),
      )
      .subscribe();
  }
  onCloseClicked() {
    this.closeClicked.emit();
  }
}
