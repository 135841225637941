import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserPermissionsStoreService } from 'ngx-common-solution';
import { Observable, filter, map } from 'rxjs';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { CurrentUserStoreService } from 'src/stores/current-user-store.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent {
  EP = EnumPermissions;
  userName$: Observable<string | undefined>;
  currentTeamName$: Observable<string | undefined>;
  currentUserHasNoPermissions$: Observable<boolean>;
  constructor(
    currentUserStore: CurrentUserStoreService,
    userPermissionsStore: UserPermissionsStoreService,
  ) {
    this.userName$ = currentUserStore.user$.pipe(
      map((user) =>
        user ? user?.firstName + ' ' + user?.lastName : undefined,
      ),
    );
    this.currentTeamName$ = currentUserStore.currentTeam$.pipe(
      map((team) => team?.teamName),
    );
    this.currentUserHasNoPermissions$ =
      userPermissionsStore.userPermissions$.pipe(
        map((permissions) => permissions.length == 0),
      );
  }
}
